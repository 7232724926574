.news-portal {
    margin-top: 10rem;
    margin-bottom: 10rem;

    .news{
        width: calc(100% - 10rem);
        margin: 1rem auto 0 auto;
        display: grid;
        grid-gap: 3rem;
        grid-template-columns: repeat(2, 1fr);
        

        .single-news{
            width: 60vw;
            img{ 
              
                width: 100%;
              
            }
            h2{
                text-align: left;
            }
            p{
                text-align: left;
            }
            .news-content{
                display: flex;
                justify-content: space-between;
                align-items: center;

                .social-icon{
                    width: 60%;
                    transition: 0.5 ease-out;
                }
                .social-icon:hover{
                    transform: scale(1.4);
                }

            }
        }

        .many-news{
            margin: 1rem;
           display: flex;
           justify-content: space-between;
           transition: 0.5s ease-in-out;

           img{
            width: 70%;
            height: 70%;
            transition: 0.5s ease-out;
           }
     img:hover{
        transform: scale(1.4);
     }
 
          h6:hover{
            text-decoration: underline;
          }

          

        }
    }
}