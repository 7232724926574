.top-items{
    margin-top: 5%;
    width: 100%;
    /* height: 100vh; */
 
}
.carousel-text{
  font-family:cursive;
  font-weight: bolder;
  font-size: 52px;
}
.carousel-para{
    font-family: serif;
    font-style: italic;
    font-size: 18px;
    font-weight: bolder;
}

.banner-height{
  height: 100vh;
}

   
@media (max-width: 1036px){

  .responsive-for-mbl{
    margin-top: 100px;
  }

}

@media (max-width: 580px){
  .responsive-for-mbl{
    margin-top: 100px;
  }

}
@media (max-width: 1024px){
  .responsive-for-mbl{
    margin-top: 100px;
  }

}
@media (max-width: 1280px){
  .responsive-for-mbl{
    margin-top: 100px;
  }


}
@media (max-width: 375px){
  .responsive-for-mbl{
    margin-top: 100px;
  }

}


