
/* .navbar-style{
    font-weight: bolder;
    color: brown;
    font-size: 26px;
} */
.nav-style{
    margin: 5px;
    padding: 10px;
    text-decoration: none;
color: gray;
font-weight: 900;
font-size: 18px;

}
.nav-style:hover{
    color: #118ab2;
}

.nav-styling{
    background-color: white;
    color: white;
  height: 15%;
}

.nav-height{
    height: 20%;
}

.header-sparkle{
    font-size: 38px;
    font-weight: bolder;
    font-style: italic;
    color: rgb(201, 196, 184);
 
}


.change-color{

 
   background-color: white;

  
/* 
    margin: auto;
    width: 40%;
    border: 5px solid white;
    padding: 10px;
    margin-top: -25px; */


}
  




.header-melody{
  color:  rgb(201, 196, 184);
    font-size: 38px;
    font-weight: bolder;
    font-style: italic;
}


.uddok-o-uddokta{

    margin-top: 25px;
    padding: 10px;

    
    }
    

    
@media (max-width: 1036px){
    .logo-height{
        width: 30%;
       
   
     }
     .nav-styling{
        height: 10%;
     }
    .uddok-o-uddokta{
      margin-top: -10px;
    }
  .mbl-bg{
    background-color: red;
  }
  }
  
  @media (max-width: 580px){
    .logo-height{
       width: 30%;
      
  
    }
    .nav-styling{
        height: 10%;
     }
    .uddok-o-uddokta{
      margin-top: -10px;
      
    }
    .mbl-bg{
        background-color: red;
      }
  
  }
  @media (max-width: 1024px){
    .logo-height{
        width: 30%;
       
   
     }
     .nav-styling{
        height: 10%;
     }
     .mbl-bg{
        background-color: red;
      }
  }
  @media (max-width: 1280px){
    .logo-height{
        width: 40%;
 margin-top: -60px;
       
   
     }
     .nav-styling{
        height: 15%;
     }
     .mbl-bg{
        background-color: white;
        margin-top: -40px;
        margin-bottom: 30px;
      }
  
  }
  @media (max-width: 375px){
    .logo-height{
        width: 30%;
       
   
     }
     .nav-styling{
        height: 30%;
     }
     .mbl-bg{
        background-color: white;
      }
    .uddok-o-uddokta{
      margin-top: -10px;
    
    }
  
  }
  
  
  