.more-items{
    padding: 20px;
    background-color: #FBEDE2;
   
   
}

.card-detail{
  width: 85%;

}
