$primary-color: #272727;
$secondary-color: navy;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}


@mixin transition-ease {
    transition: all 0.5s ease-in-out;
  }
  
  @function set-text-color($color) {
    @if (lightness($color) > 40%) {
      @return #000;
    } @else {
      @return #fff;
    }
  }


  @mixin media-md {
    @media screen and (min-width: 768px) {
      @content;
    }
  }
  @mixin media-lg {
    @media screen and (min-width: 1024px) {
      @content;
    }
  }
  @mixin media-xl {
    @media screen and (min-width: 1600px) {
      @content;
    }
  }