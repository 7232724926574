*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
   
}
.all-project{
    height: 50vh;
        width: 100%;
        background: 
        linear-gradient(
          to right,
          rgba(rgb(76, 76, 78), 0.9),
          rgba(rgb(36, 36, 40), 0.3)
        ),
        url('https://wallpaperaccess.com/full/1930875.jpg') center top;
      background-size: contain;
      background-position: contain;
      display: flex;
      flex-direction: column;
      background-blend-mode: multiply;
      align-items: center;
     z-index: -10;
      justify-content: flex-end;
}
// h1{  
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     height: 100%;
//     width: 100%;
//     color: #090979;
//     font-weight: bold;
 
// }

.projects{
    width: 75vw;
    margin: 2rem auto 0 auto;
h1{  
    margin: 1rem;
    text-align: justify;
    margin: 1rem;
    color: #2f2f72;
}
    .gender{
        text-align: justify;
        margin: 1rem;

    }
    .zero-initiatives{

        margin-top: 7rem;
        .three-zero{
    
            display: grid;
            grid-gap: 2rem;
           grid-template-columns: repeat(6, 1fr);
        }
        p{
            text-align: justify;
        }

    }
  

    .micro-healths{
        margin-top: 7rem;

       .micro-text{
        text-align: justify;
       }

        h1{ 
            margin: 1rem;
            text-align: justify;
            color: #2f2f72;
        }
        .micro-health{

            display: grid;
            grid-template-columns: repeat(6, 1fr);
            
            .micro-projects{
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-gap: 2rem;
            }

        }
    }
    
}

.gender-equality{

      h1{

        margin: 1rem;
        text-align: justify;
      }

  
  p{
    text-align: justify;
  }



}
.women-projects{
   
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    transform: 0.5s;
    grid-area: auto;
    grid-gap: 1rem;
    position: relative;
    // z-index: -1;

  img{
    border-radius: 5px;
    background-position: contain;
    background-size: cover;
    backface-visibility: inherit;
   
  }
    img:hover{
        transform: scale(1.08);

      
    }
 

}
.zero-initiatives{
    h1{ 
        margin: 1rem;
        text-align: justify;
    }
}
