.donation{
display: grid;
grid-template-columns: repeat(2,1fr);
}

.donation-img{
    height: 100%;
background-image: url("https://images.unsplash.com/photo-1477233534935-f5e6fe7c1159?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1170&q=80");
}
.donation-styling{
    width: 70%;
    height: 500px;
    margin-bottom: 40px;
    margin-top: 40px;
}

.style-donation{
    background-color: lightsteelblue;
    border-radius: 10px;
    padding: 40px;
}
.donation-text{
    font-size: 40px;
    font-weight: bolder;
    font-style: italic;
}