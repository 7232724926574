
.Event-background{
  height: 90vh;
  background: 
  linear-gradient(
    to right,
    rgba(rgb(58, 58, 64), 0.9),
    rgba(rgb(34, 34, 38), 0.3)
  ),
  url('../../Images/eventsss.jpg') center top;
  background-repeat: no-repeat;
  background-position: contain;
  background-size: cover;
  display: flex;
  flex-direction: column;

  align-items: center;
 z-index: -10;
  justify-content: flex-end;
  
  
}


.all-events{
    height: 110vh;
    width: 100%;
    background: 
    linear-gradient(
      to right,
      rgba(rgb(236, 236, 242), 0.9),
      rgba(rgb(229, 229, 244), 0.3)
    ),
    url('../../Images/News-Photo-UUBD.png') center top;

  background-repeat: no-repeat;
  background-position: contain;
  background-size: cover;
  display: flex;
  flex-direction: column;
  background-blend-mode: multiply;
  align-items: center;
 z-index: -10;
  justify-content: flex-end;
}
.events{
    width: calc(100% - 20rem);
    margin: 2rem auto 0 auto;
    display: grid;
    grid-gap: 3rem;
    grid-template-columns: repeat(2,1fr);
    padding: 1rem;
    margin-bottom: 10rem;
  
  
    p{
        text-align: justify;
        color: white;
    }

}