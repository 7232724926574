.about-styling{
   

  
}
.caroimg{
  height: 95vh;
 

}
.about-us{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  background-color: cornsilk;
}
.about-all{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  padding: 20px;
  background-color: #FBEDE2;
}
.banner-height{
  height: 70vh;
}
.history-uubd{
  text-align: justify;
}