/* .melody-text{
    margin: 10px;
    padding: 20px;
    font-family:cursive;
} */
 .heading-page{
    background-color: #FBEDE2;
    padding: 50px;
   
 }
 .welcome-page-bd{
    padding: 50px;
 }
/* .school{
    border: 3px solid #202C45;
    margin: 30px;
    padding: 20px;
}
.school:hover{
    background-color: #040a18;
    color:white;
}
.school:hover img{
   background-color: white;

}

.school:hover img{
    transform: scale(1.2);
}
.sparkle{
    font-size: 52px;
    font-weight: bolder;
    font-style: italic;
    color: #202C45;
}


.melody{
    color: #a8183c;
    font-size: 52px;
    font-weight: bolder;
    font-style: italic;
} */
