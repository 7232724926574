@import './config';

.team {
     color: navy;
     margin-bottom: 2rem;
}
.our__team{
    margin-bottom: 3rem;
}
.projects{
    padding-bottom: 4rem;
 


&__items{
    width: 70vw;
    margin: 2rem auto 0 auto;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 1rem;
}
 
&__item{
    position: relative;
    border-bottom: 5px solid $secondary-color;
    overflow: hidden;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
    }
    .item-img{
        height:30vh;
        width: 100%;
     
    }

    &::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        height: 100%;
        width: 100%;
        background: $secondary-color;
        opacity: 0;
        @include transition-ease;
   
    }
.team-contents{
    margin-top: 1rem;
}
    &:hover {
        &::after {
            top: 0;
            opacity: 0.9;
        }
        .projects__btn {
             opacity: 1;
        }
    }
}



&__btns{
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    text-align: center;


}

&__btn {
    opacity: 0;
    color: set-text-color($secondary-color);
    @include transition-ease;


    &:hover
    {
        color: set-text-color(set-text-color($secondary-color));
     

    }
}}


 
