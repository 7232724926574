.footer-style{
    padding: 40px;
    background-color: #2F2B27;
   
   
}
.footer-styling{
    border-right: 1px solid #1e6091;
}
.footer-bottom{
    color: white;
    font-weight: bold;
    font-style: italic;
}
.socials-link{
    text-decoration: none;
    color:  whitesmoke;
}