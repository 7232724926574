.middle-items{
    /* margin: 10px; */
    padding: 20px;
    background-color: #FBEDE2;
}
.img-style:hover{
    padding: 20px;
    color: crimson;
}
.instruments:hover .instruments-name{
 color: #1e6091;
transform: scale(1.01);

}
.instruments-name{
    color: #323D4A;
}
.instruments:hover{
border-left: 1px solid #1e6091;
border-right: 1px solid #1e6091;
}
.courses{
    font-size: 50px;
    font-weight: bolder;
    font-style: italic;
}